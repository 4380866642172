import { useFeatureFlags } from './FeatureFlags.tsx'

export function useDefaultRoute(): string {
  const {
    dispatchApprovalEnabled,
    portfolioOverviewEnabled,
    serviceActivityEnabled,
  } = useFeatureFlags()

  if (dispatchApprovalEnabled) return 'home/awaiting-approval'
  else if (portfolioOverviewEnabled) return 'portfolio'
  else if (serviceActivityEnabled) return 'service-activity'
  return '/'
}
