import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  Configuration,
  ServiceActivityCommercial,
  ServiceActivityControllerApi,
  ServiceActivityResidential,
  ServiceActivitySnapshot,
} from '@/generated'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'
import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'
import { useMemo } from 'react'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'

const queryKeys = {
  serviceActivity: 'serviceActivity',
  serviceActivitySnapshot: 'serviceActivitySnapshot',
}
function useServiceActivityClient() {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new ServiceActivityControllerApi(new Configuration(config))
  }, [config])
}
export function useServiceActivityQuery(): UseQueryResult<
  ServiceActivityCommercial[] | ServiceActivityResidential[]
> {
  const { isResidentialClient } = useFeatureFlags()
  const auth = useAuth()
  const serviceActivityClient = useServiceActivityClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [queryKeys.serviceActivity, clientProfileId, isResidentialClient],
    queryFn: async ({
      signal,
    }): Promise<ServiceActivityCommercial[] | ServiceActivityResidential[]> => {
      if (isResidentialClient) {
        return (
          await serviceActivityClient.getServiceActivityResidential(
            clientProfileId,
            {
              signal,
            }
          )
        ).data.serviceActivity as ServiceActivityResidential[]
      }
      return (
        await serviceActivityClient.getServiceActivityCommercial(
          clientProfileId,
          {
            signal,
          }
        )
      ).data.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useServiceActivitySnapshotQuery(): UseQueryResult<
  ServiceActivitySnapshot[]
> {
  const auth = useAuth()
  const serviceActivityClient = useServiceActivityClient()
  const clientProfileId = useClientProfileId()

  return useQuery({
    queryKey: [queryKeys.serviceActivitySnapshot, clientProfileId],
    queryFn: async ({ signal }): Promise<ServiceActivitySnapshot[]> => {
      const response = await serviceActivityClient.getServiceActivitySnapshots(
        clientProfileId,
        {
          signal,
        }
      )

      return response.data.assetSnapshots ?? []
    },
    enabled: auth.isAuthenticated,
  })
}
