import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { ServiceActivityTableData } from '@/pages/serviceActivity/residential/types.ts'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import twoValuesColumn from '@/pages/serviceActivity/residential/tableComponents/twoValuesColumn.tsx'
import { Link } from 'react-router-dom'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import { getRelativeTimeString } from '@/helpers/NativeDateTimeHelpers.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { ticketPriorityTableRowSort } from '@/components/table/sort/ticketPrioritySort.ts'

export default function useServiceActivityTableColumns() {
  return useMemo<ColumnDef<ServiceActivityTableData>[]>(
    () => [
      twoValuesColumn({
        accessorKey: 'ticketId',
        primaryHeader: 'Ticket Id',
        secondaryHeader: 'Ticket Type',
        accessorFn: ctx => {
          const { ticketId, ticketType } = ctx.row.original
          return {
            primary: (
              <Link
                to={`https://omnidian.zendesk.com/agent/tickets/${ticketId}`}
                className="tw-text-blue-600 tw-underline"
              >
                {ticketId}
              </Link>
            ),
            secondary: ticketType,
          }
        },
      }),
      {
        accessorKey: 'priority',
        header: 'Priority',
        cell: TicketPriorityCell,
        sortingFn: ticketPriorityTableRowSort(row =>
          row.original.priority.toString()
        ),
      },
      twoValuesColumn({
        accessorKey: 'customerName',
        primaryHeader: 'Customer Name',
        secondaryHeader: 'Asset ID',
        accessorFn: ctx => ({
          primary: ctx.row.original.customerName,
          secondary: ctx.row.original.assetId,
        }),
      }),
      textColumn({
        key: 'creationReason',
        header: 'Creation Reason',
      }),
      textColumn({
        key: 'ticketStatus',
        header: 'Ticket Status',
      }),
      twoValuesColumn({
        accessorKey: 'createdDate',
        primaryHeader: 'Ticket Age',
        secondaryHeader: 'Created Date',
        accessorFn: ctx => {
          const age = getRelativeTimeString(ctx.row.original.createdDate)
          const date = ctx.row.original.createdDate.toLocaleDateString()
          return {
            primary: age,
            secondary: date,
          }
        },
        enableSorting: true,
      }),
      textColumn({
        key: 'otherOpenTicketsCount',
        header: 'Other OpenTickets',
        cell: ctx => (
          <div className="tw-flex tw-flex-row-reverse">
            <Badge color="gray" className="tw-w-2">
              {ctx.row.original.otherOpenTicketsCount}
            </Badge>
          </div>
        ),
      }),
    ],
    []
  )
}
