import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'
import { useMemo } from 'react'
import {
  AssetControllerApi,
  AssetMetadata,
  Configuration,
  GetAssetCaseResponse,
  GetDailyPerformanceResponse,
  GetMonthlyPerformanceResponse,
  GetServiceActivityCommercialResponse,
} from '@/generated'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'

function useAssetClient(): AssetControllerApi {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new AssetControllerApi(new Configuration(config))
  }, [config])
}

const assetQueryKeys = {
  metadata: 'assetMetadata',
  assetServiceActivity: 'assetServiceActivity',
  assetCase: 'assetCase',
  dailyPerformance: 'dailyPerformance',
  monthlyPerformance: 'monthlyPerformance',
}

export function useAssetMetadataQuery(
  assetId: number
): UseQueryResult<AssetMetadata> {
  const auth = useAuth()
  const client = useAssetClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [assetQueryKeys.metadata, assetId, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await client.getAssetMetadata(assetId, clientProfileId, {
        signal,
      })
      return response.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useAssetServiceActivityQuery(
  assetId: number,
  includeClosedTickets?: boolean
): UseQueryResult<GetServiceActivityCommercialResponse> {
  const auth = useAuth()
  const client = useAssetClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [
      assetQueryKeys.assetServiceActivity,
      assetId,
      includeClosedTickets,
      clientProfileId,
    ],
    queryFn: async ({ signal }) => {
      const response = await client.getAssetServiceActivity(
        assetId,
        clientProfileId,
        includeClosedTickets,
        {
          signal,
        }
      )
      return response.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useAssetCaseQuery(
  assetId: number,
  caseId: string
): UseQueryResult<GetAssetCaseResponse> {
  const auth = useAuth()
  const client = useAssetClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [assetQueryKeys.assetCase, assetId, caseId, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await client.getAssetCase(
        assetId,
        caseId,
        clientProfileId,
        {
          signal,
        }
      )
      return response.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useAssetPerformanceDailyQuery(
  assetId: number,
  isoStartDate: string,
  isoEndDate: string
): UseQueryResult<GetDailyPerformanceResponse> {
  const auth = useAuth()
  const client = useAssetClient()
  const clientProfileId = useClientProfileId()

  return useQuery<GetDailyPerformanceResponse>({
    queryKey: [
      assetQueryKeys.dailyPerformance,
      assetId,
      isoStartDate,
      isoEndDate,
      clientProfileId,
    ],
    queryFn: async ({ signal }) => {
      const response = await client.getAssetPerformanceDaily(
        assetId,
        isoStartDate,
        isoEndDate,
        clientProfileId,
        { signal }
      )
      return response.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useAssetPerformanceMonthlyQuery(
  assetId: number,
  isoStartDate: string,
  isoEndDate: string
): UseQueryResult<GetMonthlyPerformanceResponse> {
  const auth = useAuth()
  const client = useAssetClient()
  const clientProfileId = useClientProfileId()

  return useQuery<GetMonthlyPerformanceResponse>({
    queryKey: [
      assetQueryKeys.monthlyPerformance,
      assetId,
      isoStartDate,
      isoEndDate,
      clientProfileId,
    ],
    queryFn: async ({ signal }) => {
      const response = await client.getAssetPerformanceMonthly(
        assetId,
        isoStartDate,
        isoEndDate,
        clientProfileId,
        { signal }
      )
      return response.data
    },
    enabled: auth.isAuthenticated,
  })
}
